const loadedScripts = [];
const loadedStyles = [];

function createScript(path, isModule) {
    const script = document.createElement("script");
    script.setAttribute("src", path);
    script.setAttribute("type", (isModule ? "module" : "text/javascript"));
    document.body.appendChild(script);
}

function createStyle(path) {
    const style = document.createElement("link");
    style.setAttribute("rel", "stylesheet");
    style.setAttribute("href", path);
    document.body.appendChild(style);
}

window.nurun = {
    loadDependencies: (type, path, isModule) => {
        if (type === "js") {
            const existingScript = loadedScripts.find(
                (script) => script === path
            );
            if (!existingScript) {
                loadedScripts.push(path);
                createScript(path, isModule);
            }
        }

        if (type === "css") {
            const existingStyle = loadedStyles.find((style) => style === path);
            if (!existingStyle) {
                loadedStyles.push(path);
                createStyle(path);
            }
        }
    },
};